import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import activitiesService from '../../../../services/activitiesService';
import partnersService from '../../../../services/partnersService';
import splitArray from '../../../../utils/splitArray';
import { ActivitiesListFromApiType, CategoriesFromApiResponse, MappedActivitiesListType, PartnersFromApiResponse } from '../../types';

interface UseLoadActivitiesInterface {
  setCurrentPage: Dispatch<SetStateAction<number>>
}

type CategoryLiteralsType = {
  [key: string]: string;
}

export default function useLoadActivities({
  setCurrentPage,
}: UseLoadActivitiesInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(true);
  const [fullActivities, setFullActivities] = useState<MappedActivitiesListType[]>([]);
  const [activitiesSplited, setActivitiesSplited] = useState<Array<MappedActivitiesListType[]>>([]);
  const [filteredActivities, setFilteredActivities] = useState<MappedActivitiesListType[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [categoriesList, setCategoriesList] = useState([{ value: '', label: 'Todas' }]);
  const [subcategoriesList, setSubcategoriesList] = useState([{ category: '', subcategory: 'Todas' }]);
  const [partnersList, setPartnersList] = useState([{ value: '', label: 'Todos' }]);

  const { apiCall } = useApiCall();

  const loadActivities = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivitiesList,
      actionAfterResponse: (apiResponse) => {
        const categoryLiterals: CategoryLiteralsType = {
          'Atividades Educacionais': 'Atividades Educacionais',
          'Atividade Educacional': 'Atividades Educacionais',
          Educação: 'Atividades Educacionais',
          Entretenimento: 'Entretenimento',
          Lazer: 'Entretenimento',
          Cultura: 'Entretenimento',
          'Atividade Física': 'Atividades Físicas',
          'Atividades Físicas': 'Atividades Físicas',
        };
        const activitiesListFromApi: ActivitiesListFromApiType = apiResponse.list;
        if (!activitiesListFromApi) {
          toast.error(`Não foi possível carregar a lista de atividades (${apiResponse.error})`, {
            toastId: 'loadActivitiesFail',
          });
          setDoesListApiHasError(true);
          return;
        }
        const activitiesList = activitiesListFromApi;
        const mappedActivitiesList = activitiesList.map((activity) => ({
          id: activity.id,
          name: activity.nome,
          status: (activity.ativo ? 'Ativo' : 'Inativo'),
          hasRatings: activity.hasRatings,
          ratingsAverage: activity.ratingsAverage,
          generalData: {
            status: (activity.ativo ? 'Ativo' : 'Inativo'),
            partner: activity.partner?.id,
            featPartner: activity.featPartner?.id,
            category: categoryLiterals[activity.categoria],
            subcategory: activity.subcategoria,
            ageRange: [activity.minima, activity.maxima],
            kind: activity.tipo === 'record' ? 'Aula em vídeo' : 'Aula ao vivo',
          },
        }));
        setFullActivities(mappedActivitiesList);

        const splitedArray = splitArray(mappedActivitiesList);
        setActivitiesSplited(splitedArray);
        setFilteredActivities(splitedArray[0]);
        setCurrentPage(0);
        setDoesListApiHasError(false);
      },
      catchMessage: 'Não foi possível carregar a lista de atividades',
    })
  }, [apiCall, setCurrentPage]);

  const getCategoriesList = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getCategories,
      actionAfterResponse: (apiResponse) => {
        const categoriesListFromApi: CategoriesFromApiResponse = apiResponse.categories;
        const categoriesOptions = [{ value: 'Atividades Educacionais', label: 'Atividades Educacionais'}, { value: "Atividades Físicas", label: 'Atividades Físicas'}, { value: 'Entretenimento', label: 'Entretenimento' }]
        categoriesOptions.unshift({
          value: '',
          label: 'Todas',
        });

        const subcategoriesMap = categoriesListFromApi.map((ctg) => ({
          category: ctg.type, subcategory: ctg.name
        }));
        const subcategoriesFlatted = subcategoriesMap.flat();

        setCategoriesList(categoriesOptions);
        setSubcategoriesList(subcategoriesFlatted);
      },
      catchMessage: 'Não foi possível carregar as categorias e subcategorias para utilizar os filtros. Por favor, tente novamente',
    })
  }, [apiCall]);

  const getPartnersList = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.getResumedPartnersList,
      actionAfterResponse: (apiResponse) => {
        const partnersListFromApi: PartnersFromApiResponse = apiResponse.list;
        const mappedPartners = partnersListFromApi.map((partner) => ({
          value: partner.id, label: partner.nome_fantasia,
        }));
        setPartnersList(mappedPartners);
      },
      catchMessage: 'Não foi possível carregar a lista de parceiros para utilizar o filtro. Por favor, tente novamente',
    })
  }, [apiCall]);

  const loadPage = useCallback(async () => {
    try {
      setIsLoadLoading(true);
      await Promise.all([
        loadActivities(),
        getCategoriesList(),
        getPartnersList(),
      ]);
    } catch (error) {
      toast.error(`Não foi possível carregar a lista de atividades (${error})`);
    } finally {
      setIsLoadLoading(false);
    }
  }, [getCategoriesList, getPartnersList, loadActivities]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return {
    isLoadLoading,
    fullActivities,
    doesListApiHasError,
    filteredActivities,
    setFilteredActivities,
    loadActivities,
    categoriesList,
    partnersList,
    activitiesSplited,
    setActivitiesSplited,
    subcategoriesList,
    loadPage,
  };
}
