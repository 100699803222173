import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApiCall from "../../../../../hooks/useApiCall";
import useErrors from "../../../../../hooks/useErrors";
import booksService from "../../../../../services/booksService";
import partnersService from "../../../../../services/partnersService";
import replaceSpecialChars from "../../../../../utils/replaceSpecialChars";
import uploadAndGetFileUrlFirebase from "../../../../../utils/uploadAndGetFileUrlFirebase";
import { BookType, BookTypeType, CategoriesFromApiResponse, PartnersFromApiResponse, TecTecaApiResponse } from "../../../types";

export default function useBookForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState<string>('');
  const [selectedExternalBook, setSelectedExternalBook] = useState({} as { value: string; label: string });
  const [type, setType] = useState<BookTypeType>('internal');
  const [author, setAuthor] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [partner, setPartner] = useState({} as { value: string; label: string });
  const [category, setCategory] = useState({} as { value: string; label: string });
  const [level, setLevel] = useState({} as { value: string; label: string });
  const [coverFile, setCoverFile] = useState<File | undefined | string>(undefined);
  const [epubFileName, setEpubFileName] = useState<string>('');
  const [epubFile, setEpubFile] = useState<File | undefined | string>(undefined);

  const [externalBooksList, setExternalBooksList] = useState<TecTecaApiResponse[]>([]);
  const [partnersList, setPartnersList] = useState<{ value: string; label: string }[]>([]);
  const [categoriesList, setCategoriesList] = useState<{ value: string; label: string }[]>([]);

  const levelOptions = useMemo(() => ([
    { value: '', label: 'Selecione um nível' },
    { value: 'beginner', label: 'Iniciante' },
    { value: 'medium', label: 'Intermediário' },
    { value: 'advanced', label: 'Avançado' },
  ]), []);

  const { errors, getErrorMessageByFieldName, removeError, setError } = useErrors();
  const { apiCall } = useApiCall();
  const { id } = useParams();
  const navigate = useNavigate();

  const getFile: (url: string) => Promise<File | undefined> = async (url: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      // Inferir o nome do arquivo a partir da URL, caso não exista o Content-Disposition
      let fileName = '';
      const contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch?.[1]) {
          fileName = fileNameMatch[1];
        }
      } else {
        // Extrair o nome do arquivo da URL
        const urlSegments = url.split('/');
        fileName = urlSegments[urlSegments.length - 1] || 'arquivo-sem-nome';
      }

      // Baixar o arquivo como ArrayBuffer e criar um objeto File
      const arrayBuffer = await response.arrayBuffer();
      const file = new File([arrayBuffer], fileName, {
        type: response.headers.get('Content-Type') || 'application/octet-stream',
      });

      console.log('Arquivo baixado:', file);
      return file;
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  const downloadFile = (file: File) => {
    console.log('downloadFile', file);
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTitle(event.target.value);

    if (!event.target.value) {
      setError({ field: 'title', message: 'Título é obrigatório!' });
    } else {
      removeError('title');
    }
  }

  async function handleSelectedExternalBookChange(selectedExternalBook: { value: string; label: string }) {
    setIsLoading(true);
    try {
      setSelectedExternalBook(selectedExternalBook);

      const correspondingBook = externalBooksList.find(book => book.id === selectedExternalBook.value);
      setDescription(correspondingBook?.description || '');
      setAuthor(correspondingBook?.author || '');
      setTitle(correspondingBook?.name || '');

      const [epub, cover] = await Promise.all([
        getFile(correspondingBook?.epub || ''),
        getFile(correspondingBook?.cover || ''),
      ]);

      setCoverFile(cover || undefined);
      setEpubFile(epub || undefined);
      setEpubFileName(epub?.name ||  `book-${selectedExternalBook.value}.epub`);
    } catch (error) {
      console.error('Erro ao carregar livro externo:', error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleTypeChange(event: BookTypeType) {
    setType(event);
  }

  function handleAuthorChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAuthor(event.target.value);

    if (!event.target.value) {
      setError({ field: 'author', message: 'Autor é obrigatório!' });
    } else {
      removeError('author');
    }
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setDescription(event.target.value);

    if (!event.target.value) {
      setError({ field: 'description', message: 'Descrição é obrigatória!' });
    } else {
      removeError('description');
    }
  }

  function handlePartnerChange(partner: { value: string; label: string }) {
    setPartner(partner);
  }

  function handleCategoryChange(category: { value: string; label: string }) {
    setCategory(category);
  }

  function handleLevelChange(level: { value: string; label: string }) {
    setLevel(level);
  }

  function handleEpubUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setEpubFile(event.target.files[0]);
      setEpubFileName(event.target.files[0].name);
    }
  }

  function handleRemoveEpub() {
    setEpubFile(undefined);
    setEpubFileName('');
  }

  function downloadEpub() {
    console.log('downloadEpubOI', epubFile);
    if (epubFile && typeof epubFile !== 'string') {
      console.log('downloadEpub', epubFile);
      downloadFile(epubFile);
    }
  }

  const getCategoriesList = useCallback(async () => {
    await apiCall({
      apiToCall: booksService.getCategories,
      actionAfterResponse: (apiResponse) => {
        const categoriesListFromApi: CategoriesFromApiResponse = apiResponse.categories;
        const categoriesOptions = categoriesListFromApi.map((ctg) => ({ value: ctg.id, label: ctg.name }));

        setCategoriesList(categoriesOptions);
      },
      catchMessage: 'Não foi possível carregar as categorias para carregar o formulario. Por favor, tente novamente',
    })
  }, [apiCall]);

  const getPartnersList = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.getResumedPartnersList,
      actionAfterResponse: (apiResponse) => {
        const partnersListFromApi: PartnersFromApiResponse = apiResponse.list;
        const mappedPartners = partnersListFromApi.map((partner) => ({
          value: partner.id, label: partner.nome_fantasia,
        }));
        setPartnersList(mappedPartners.filter((x) => !!x.value));
      },
      catchMessage: 'Não foi possível carregar a lista de parceiros para carregar o formulario. Por favor, tente novamente',
    })
  }, [apiCall]);

  const getExternalBooksList = useCallback(async () => {
    await apiCall({
      apiToCall: booksService.getExternalBooksList,
      actionAfterResponse: (apiResponse) => {
        setExternalBooksList(apiResponse.externalBooks);
      },
      catchMessage: 'Não foi possível carregar a lista de livros externos para carregar o formulario. Por favor, tente novamente',
      catchAction: () => { navigate('/books'); },
      onStartLoad: () => { setIsLoading(true); },
      onEndLoad: () => { setIsLoading(false); },
    })
  }, [apiCall, navigate]);

  const loadBook = useCallback(async () => {
    await apiCall({
      apiToCall: booksService.getBookDetails,
      queryParams: { id },
      actionAfterResponse: async (apiResponse: { success: boolean, details: BookType }) => {
        if (!apiResponse.details) {
          toast.error(`Não foi possível carregar o livro`, {
            toastId: 'getBookFail',
          });
          navigate('/books');
          return;
        }

        const bookDetails = apiResponse.details;
        setTitle(bookDetails.title);
        setSelectedExternalBook({ value: bookDetails.idAtPartner || '', label: bookDetails.title });
        setType(bookDetails.type);
        setAuthor(bookDetails.author);
        setDescription(bookDetails.description);
        setPartner({ value: bookDetails.partner.id, label: bookDetails.partner.nome_fantasia });
        setCategory({ value: bookDetails.category.id, label: bookDetails.category.name });
        setEpubFileName(bookDetails.linkEpub || '');
        setEpubFile(bookDetails.linkEpub || undefined);
        setCoverFile(bookDetails.cover || undefined);
        setLevel({ value: bookDetails.level, label: levelOptions.find((opt) => opt.value === bookDetails.level)?.label || '' });
      },
      catchAction: () => { navigate('/books'); },
    })
  }, [apiCall, id, levelOptions, navigate]);

  const loadForm = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        getCategoriesList(),
        getPartnersList(),
        getExternalBooksList(),
      ]);
      if (id) {
        await loadBook();
      }
    } catch (error) {
      toast.error(`Não foi possível carregar o formulário. Por favor, tente novamente (${error})`);
      navigate('/books');
    } finally {
      setIsLoading(false);
    }
  }, [getCategoriesList, getPartnersList, id, loadBook, navigate, getExternalBooksList]);

  useEffect(() => {
    loadForm();
  }, [id, loadForm]);

  const updateBook = useCallback(async () => {
    setIsLoading(true);

    const firebaseEpubUrl = (!!epubFile && typeof epubFile !== 'string') ? await uploadAndGetFileUrlFirebase({
      file: epubFile,
      firebaseRefPath: `books-files/${replaceSpecialChars(partner.label)}/${replaceSpecialChars(title)}/${(epubFile as File).name}`,
    }) : epubFile as string;

    const firebaseCoverUrl = (!!coverFile && typeof coverFile !== 'string') ? await uploadAndGetFileUrlFirebase({
      file: coverFile as File,
      firebaseRefPath: `books-files/${replaceSpecialChars(partner.label)}/${replaceSpecialChars(title)}/${(coverFile as File).name}`,
    }) : coverFile as string;

    await apiCall({
      apiToCall: booksService.updateBook,
      queryParams: { id },
      reqBody: JSON.stringify({
        partnerId: partner.value,
        idAtPartner: type === 'external' ? selectedExternalBook.value : '',
        type,
        active: true,
        title,
        categoryId: category.value,
        author,
        description,
        linkEpub: firebaseEpubUrl,
        cover: firebaseCoverUrl,
        level: level.value,
      }),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.success) {
          toast.success('Livro atualizado com sucesso!');
          navigate('/books');
          return;
        }

        toast.error('Não foi possível atualizar o livro. Por favor, tente novamente');
      },
      catchMessage: 'Não foi possível atualizar o livro. Por favor, tente novamente',
      onStartLoad: () => { setIsLoading(true); },
      onEndLoad: () => { setIsLoading(false); },
    })
  }, [apiCall, author, category.value, coverFile, description, epubFile, id, level.value, navigate, partner.label, partner.value, selectedExternalBook.value, title, type]);

  const createBook = useCallback(async () => {
    setIsLoading(true)

    const firebaseEpubUrl = (epubFile && typeof epubFile !== 'string') ? await uploadAndGetFileUrlFirebase({
      file: epubFile,
      firebaseRefPath: `books-files/${replaceSpecialChars(partner.label)}/${replaceSpecialChars(title)}/${(epubFile as File).name}`,
    }) : epubFile as string;

    const firebaseCoverUrl = (coverFile && typeof epubFile !== 'string') ? await uploadAndGetFileUrlFirebase({
      file: coverFile as File,
      firebaseRefPath: `books-files/${replaceSpecialChars(partner.label)}/${replaceSpecialChars(title)}/${(coverFile as File).name}`,
    }) : coverFile as string;

    await apiCall({
      apiToCall: booksService.createBook,
      reqBody: JSON.stringify({
        partnerId: partner.value,
        idAtPartner: type === 'external' ? selectedExternalBook.value : '',
        type,
        active: true,
        title,
        categoryId: category.value,
        author,
        description,
        linkEpub: firebaseEpubUrl,
        cover: firebaseCoverUrl,
        level: level.value,
      }),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.success) {
          toast.success('Livro criado com sucesso!');
          navigate('/books');
          return;
        }

        toast.error('Não foi possível criar o livro. Por favor, tente novamente');
      },
      catchMessage: 'Não foi possível criar o livro. Por favor, tente novamente',
      onStartLoad: () => { setIsLoading(true); },
      onEndLoad: () => { setIsLoading(false); },
    })
  }, [apiCall, author, category.value, coverFile, description, epubFile, level.value, navigate, partner.label, partner.value, selectedExternalBook.value, title, type]);

  const canSubmit = useMemo(() => (
    title && author && description && partner.value && category.value && errors.length === 0 && ((type === 'internal' && coverFile && epubFile && epubFileName && level.value) || (type === 'external' && selectedExternalBook.value))
  ), [title, author, description, partner.value, category.value, errors.length, type, coverFile, epubFile, epubFileName, level.value, selectedExternalBook.value]);

  return {
    isLoading,
    getErrorMessageByFieldName,
    title,
    handleTitleChange,
    externalBooksList,
    selectedExternalBook,
    handleSelectedExternalBookChange,
    type,
    handleTypeChange,
    author,
    handleAuthorChange,
    description,
    handleDescriptionChange,
    partnersList,
    partner,
    handlePartnerChange,
    categoriesList,
    category,
    handleCategoryChange,
    coverFile,
    setCoverFile,
    handleEpubUpload,
    handleRemoveEpub,
    epubFileName,
    downloadEpub,
    canSubmit,
    setType,
    updateBook,
    createBook,
    level,
    setLevel,
    levelOptions,
    handleLevelChange,
  }
}
