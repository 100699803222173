import Loader from "../../../components/Loader";
import NoData from "../../../components/NoData";
import PageHeader from "../../../components/PageHeader";
import Sidebar from "../../../components/Sidebar";
import Transitions from "../../../components/Transition";
import AddModal from "./components/AddModal";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import ListHeader from "./components/header";
import List from "./components/list";
import Search from "./components/Search";
import useBookCategories from "./useCategories";

export default function BookCategories() {
  const {
    isLoading,
    categories,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredList,
    handleTryAgain,
    handleOpenEditModal,
    categoryBeingEditted,
    setEditModalShow,
    editModalShow,
    setAddModalShow,
    addModalShow,
    loadCategories,
    setIsLoading,
    handleOpenDeleteModal,
    categoryBeingDeleted,
    setDeleteModalShow,
    deleteModalShow,
  } = useBookCategories();

  const hasCategories = categories?.length !== 0 && !!categories;
  const filteredListLength = filteredList?.length ?? 0;
  const searchNotFound = filteredListLength === 0 && hasCategories;
  const isListEmpty = !doesListApiHasError && (!isLoading && categories.length === 0);

  return (
    <>
      <Sidebar active="Books" />
      <Transitions>
        <Loader isLoading={isLoading} />

        <PageHeader
          title={`Gerenciamento de categorias (Livros)`}
          link={'/books'}
        />

        {hasCategories && (
          <Search
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
          />
        )}

        <ListHeader
          doesListExists={hasCategories}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel={'Categoria'}
          pluralLabel={'Categorias'}
          setAddModalShow={setAddModalShow}
        />

        {hasCategories && (
          <List
            filteredList={filteredList}
            onOpenEditModal={handleOpenEditModal}
            onOpenDeleteModal={handleOpenDeleteModal}
          />
        )}

        <AddModal
          addModalShow={addModalShow}
          setAddModalShow={setAddModalShow}
          loadCategories={loadCategories}
          setIsLoading={setIsLoading}
        />

        <EditModal
          editModalShow={editModalShow}
          setEditModalShow={setEditModalShow}
          setIsLoading={setIsLoading}
          loadCategories={loadCategories}
          categoryBeingEditted={categoryBeingEditted}
        />

        <DeleteModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          setIsLoading={setIsLoading}
          loadCategories={loadCategories}
          categoryBeingDeleted={categoryBeingDeleted}
        />

{searchNotFound && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Nenhum resultado foi encontrado conforme filtros selecionados, por favor, tente alterar seus critérios de busca.
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista de categorias.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma categoria cadastrada, clique no botão
                {' '}
                <strong>Nova Categoria.</strong>
                {' '}
                para cadastrar sua primeira!
              </>
)}
          />
        )}
      </Transitions>
    </>
  )
}
