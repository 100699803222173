import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import partnersService from '../../../../services/partnersService';
import splitArray from '../../../../utils/splitArray';
import { MappedPartnerType, PartnerFromListApiType } from '../../types';

interface UseLoadPartnersInterface {
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setPartnersSplited: Dispatch<SetStateAction<Array<MappedPartnerType[]>>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setFilteredPartners: Dispatch<SetStateAction<MappedPartnerType[]>>;
}

export default function useLoadPartners({
  setSearchTerm,
  setPartnersSplited,
  setCurrentPage,
  setFilteredPartners,
}: UseLoadPartnersInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [fullPartners, setFullPartners] = useState<MappedPartnerType[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const { apiCall } = useApiCall();

  const loadPartners = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.getPartnersList,
      onStartLoad: () => {
        setSearchTerm('');
        setIsLoadLoading(true);
      },
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a lista de parceiros (${apiResponse.error})`, {
            toastId: 'getPartnersFail',
          });
          setDoesListApiHasError(true);
          setIsLoadLoading(false);
          return;
        }
        const partnersList: PartnerFromListApiType[] = apiResponse.list;
        const mappedPartnersList = partnersList?.map((partner) => ({
          id: partner.id,
          name: partner.nome_fantasia,
          status: partner?.ativo ? 'Ativo' : 'Inativo',
          cnpj: partner.cnpj,
          cpf: partner.cpf,
          companyName: partner.razao_social || 'Pessoa Física',
          contactName: partner.contato_nome,
          contactCellphone: partner.contato_celular,
          contactPhone: partner.contato_telefone,
          contactEmail: partner.contato_email,
          responsible: partner.responsavel_nome,
          responsibleRole: partner.responsavel_cargo,
          responsiblePhone: partner.responsavel_telefone,
          responsibleEmail: partner.responsavel_email,
          city: partner.end_cidade,
          uf: partner.end_uf,
          profileImage: partner.profileImage,
          hasRatings: partner.hasRatings,
          ratingsAverage: partner.ratingsAverage,
        }));
        setFullPartners(mappedPartnersList);

        const splitedArray = splitArray(mappedPartnersList);
        setPartnersSplited(splitedArray);
        setCurrentPage(0);
        setFilteredPartners(splitedArray[0]);
        setDoesListApiHasError(false);
      },
      catchAction: () => setDoesListApiHasError(true),
      catchMessage: 'Não foi possível carregar a lista dos colaboradores'
    })
  }, [apiCall, setCurrentPage, setFilteredPartners, setPartnersSplited, setSearchTerm]);

  useEffect(() => {
    loadPartners();
  }, [loadPartners]);

  return {
    isLoadLoading,
    fullPartners,
    doesListApiHasError,
    loadPartners,
  };
}
