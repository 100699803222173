import { Link } from 'react-router-dom';
import { ActiveItem, MenuItem } from '../styles';

import activity from '../../../assets/images/icons/activity.svg';
import book from '../../../assets/images/icons/book.svg';
import calendar from '../../../assets/images/icons/calendar.svg';
import chartPie from '../../../assets/images/icons/chartPie.svg';
import comments from '../../../assets/images/icons/comments.svg';
import doubleCheck from '../../../assets/images/icons/doubleCheck.svg';
import handshake from '../../../assets/images/icons/handshake.svg';
import lock from '../../../assets/images/icons/lock.svg';
import subscribers from '../../../assets/images/icons/subscribers.svg';
import user from '../../../assets/images/icons/user.svg';

import { SidebarInterface } from '../interface';

export default function CoordinatorSidebar({ active }: SidebarInterface) {
  return (
    <>
      {active === 'Dashboard'
        ? (
          <ActiveItem>
            <Link to="/">
              <MenuItem>
                <img src={chartPie} alt="dashboard" />
                Dashboard
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/">
            <MenuItem>
              <img src={chartPie} alt="dashboard" />
              Dashboard
            </MenuItem>
          </Link>
        )}

      {active === 'Forum'
        ? (
          <ActiveItem>
            <Link to="/forum">
              <MenuItem>
                <img src={comments} alt="forum" />
                Forum
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forum">
            <MenuItem>
              <img src={comments} alt="forum" />
              Forum
            </MenuItem>
          </Link>
        )}

      {active === 'ForumInternal'
        ? (
          <ActiveItem>
            <Link to="/forumInternal">
              <MenuItem>
                <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
                Forum interno
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forumInternal">
            <MenuItem>
              <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
              Forum interno
            </MenuItem>
          </Link>
        )}

      {active === 'ChangePassword'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={lock} alt="lock" />
                Alterar senha
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/changePassword">
            <MenuItem>
              <img src={lock} alt="lock" />
              Alterar senha
            </MenuItem>
          </Link>
        )}

      {active === 'Subscribers'
        ? (
          <ActiveItem>
            <Link to="/subscribers">
              <MenuItem>
                <img src={subscribers} alt="subscribers" />
                Assinantes
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/subscribers">
            <MenuItem>
              <img src={subscribers} alt="subscribers" />
              Assinantes
            </MenuItem>
          </Link>
        )}

      {active === 'Activities'
        ? (
          <ActiveItem>
            <Link to="/activities">
              <MenuItem>
                <img src={activity} alt="activity" />
                Atividades
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/activities">
            <MenuItem>
              <img src={activity} alt="activity" />
              Atividades
            </MenuItem>
          </Link>
        )}

      {active === 'Approve'
        ? (
          <ActiveItem>
            <Link to="/activitiesApprove">
              <MenuItem>
                <img src={doubleCheck} alt="activity" />
                Aprovações de atividades
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/activitiesApprove">
            <MenuItem>
              <img src={doubleCheck} alt="activity" />
              Aprovações de atividades
            </MenuItem>
          </Link>
        )}

      {active === 'Schedules'
        ? (
          <ActiveItem>
            <Link to="/schedules">
              <MenuItem>
                <img src={calendar} alt="schedule" />
                Aulas/Agendamentos
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/schedules">
            <MenuItem>
              <img src={calendar} alt="schedule" />
              Aulas/Agendamentos
            </MenuItem>
          </Link>
        )}

{active === 'Books'
        ? (
          <ActiveItem>
            <Link to="/books">
              <MenuItem>
                <img src={book} alt="book" />
                Livros
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/books">
            <MenuItem>
              <img src={book} alt="book" />
              Livros
            </MenuItem>
          </Link>
        )}

      {active === 'Partners'
        ? (
          <ActiveItem>
            <Link to="/partners">
              <MenuItem>
                <img src={handshake} alt="handshake" />
                Parceiros
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/partners">
            <MenuItem>
              <img src={handshake} alt="handshake" />
              Parceiros
            </MenuItem>
          </Link>
        )}

      {active === 'Users'
        ? (
          <ActiveItem>
            <Link to="/users">
              <MenuItem>
                <img src={user} alt="user" />
                Usuários
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/users">
            <MenuItem>
              <img src={user} alt="user" />
              Usuários
            </MenuItem>
          </Link>
        )}
    </>
  );
}
