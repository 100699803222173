import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import removeDuplicates from '../../../../utils/removeDuplicates';
import splitArray from '../../../../utils/splitArray';
import { MappedUserType, UserFromApi } from '../../types';

interface UseLoadUsersInterface {
  setUsersSplitted: Dispatch<SetStateAction<Array<MappedUserType[]>>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setFilteredUsers: Dispatch<SetStateAction<MappedUserType[]>>;
}

export default function useLoadUsers({
  setUsersSplitted,
  setCurrentPage,
  setFilteredUsers,
}: UseLoadUsersInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [users, setUsers] = useState<MappedUserType[]>([]);
  const [usersFullData, setUsersFullData] = useState<UserFromApi[]>([]);
  const [companysList, setCompanysList] = useState([{ value: '', label: 'Todas' }]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const statusOptions = useMemo(() => (
    [
      { value: '', label: 'Todos' },
      { value: 'Cadastrado', label: 'Cadastrados' },
      { value: 'Ativa', label: 'Ativos' },
      { value: 'Cancelada', label: 'Cancelados' },
      { value: 'Período de teste', label: 'Período de teste' },
      { value: 'Suspensa', label: 'Suspensos' },
    ]
  ), []);

  const { apiCall } = useApiCall();

  const loadUsers = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersList,
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a lista de usuários (${apiResponse.error})`, {
            toastId: 'getUsersFail',
          });
          setDoesListApiHasError(true);
          setIsLoadLoading(false);
          setUsers([]);
          return;
        }
        const usersList: UserFromApi[] = apiResponse.list;
        setUsersFullData(usersList);
        const mappedUsersList = usersList?.map((user) => ({
          id: user.id,
          name: user.sobrenome ? `${user.nome} ${user.sobrenome}` : user.nome,
          status: user.status,
          registrationDate: user.data_cadastro,
          activationDate: user.data_ativacao ?? '',
          cellphone: user.celular,
          cpf: user.cpf,
          company: user.empresa || 'B2C',
          companyId: user.companyId || undefined,
          ratings: user.ratings_app,
          kids: user.kids,
          email: user.email
        }));
        setUsers(mappedUsersList);

        const splittedArray = splitArray(mappedUsersList);
        setUsersSplitted(splittedArray);
        setCurrentPage(0);
        setFilteredUsers(splittedArray[0]);
        setDoesListApiHasError(false);

        const companys = removeDuplicates(mappedUsersList.map((usr) => ({ value: usr.companyId, label: usr.company })));
        companys.unshift({ value: '', label: 'Todas' });
        const companiesFiltered = companys.filter((company) => company.label !== 'JaSouKiddle');
        setCompanysList(companiesFiltered);
      },
      catchMessage: 'Não foi possível carregar a lista dos usuários',
      catchAction: () => setDoesListApiHasError(true)
    })
  }, [apiCall, setCurrentPage, setFilteredUsers, setUsersSplitted]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return {
    isLoadLoading,
    users,
    usersFullData,
    companysList,
    doesListApiHasError,
    loadUsers,
    statusOptions,
  };
}
