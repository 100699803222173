import RangeSlider from 'react-range-slider-input';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ActiveItem, GroupFileInput, MenuItem } from '../styles';

import activity from '../../../assets/images/icons/activity.svg';
import bell from '../../../assets/images/icons/bell.svg';
import bellSlash from '../../../assets/images/icons/bellSlash.svg';
import book from '../../../assets/images/icons/book.svg';
import calendar from '../../../assets/images/icons/calendar.svg';
import calendarX from '../../../assets/images/icons/calendarX.svg';
import chartPie from '../../../assets/images/icons/chartPie.svg';
import givenClass from '../../../assets/images/icons/class.svg';
import comments from '../../../assets/images/icons/comments.svg';
import company from '../../../assets/images/icons/company.svg';
import cupom from '../../../assets/images/icons/cupom.svg';
import doubleCheck from '../../../assets/images/icons/doubleCheck.svg';
import email from '../../../assets/images/icons/email.svg';
import financial from '../../../assets/images/icons/financial.svg';
import handshake from '../../../assets/images/icons/handshake.svg';
import lock from '../../../assets/images/icons/lock.svg';
import star from '../../../assets/images/icons/star.svg';
import subscribers from '../../../assets/images/icons/subscribers.svg';
import user from '../../../assets/images/icons/user.svg';
import userTie from '../../../assets/images/icons/userTie.svg';
import whatsapp from '../../../assets/images/icons/whatsapp.svg';

import styled from 'styled-components';
import isValidMongoId from '../../../utils/isValidMongoId';
import { CustomStyle } from '../../CustomSelectStyle';
import FileInput from '../../FileInput';
import FilterRadioButton from '../../FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../FilterRadioButtonsContainer';
import FormGroup from '../../FormGroup';
import Input from '../../Input';
import Loader from '../../Loader';
import MyModal from '../../Modal';
import Textarea from '../../Textarea';
import { SidebarInterface } from '../interface';
import usePushNotifications from '../usePushNotifications';
import useSendEmail from '../useSendEmail';
import useSendWhatsapp from '../useSendWhatsapp';

const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  position: relative;
  bottom: 10px;
  margin: 16px;
  margin-top: 24px;

  @media(max-width: 500px) {
      width: 50%;
      margin-top: 8px;
    }
  header {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export default function AdminSidebar({ active }: SidebarInterface) {
  const {
    modalShow,
    setModalShow,
    sendPushNotification,
    selectedTarget,
    targetOptions,
    setSelectedTarget,
    handleNotificationMessageChange,
    notificationMessage,
    isLoading,
    setWillSend,
    willSend,
    isScheduled,
    setIsScheduled,
    date,
    handleScheduledDateChange,
    canSubmit,
    selectedAgeRange,
    handleAgeSelection,
    setSelectedAgeRange,
  } = usePushNotifications();

  const {
    whatsModalShow,
    setWhatsModalShow,
    setWillSendWhats,
    willSendWhats,
    sendWhats,
    selectedTargetWhats,
    whatsMessage,
    whatsMessageType,
    targetWhatsOptions,
    setSelectedWhatsTarget,
    selectedWhatsTarget,
    // selectedAgeRangeWhats,
    // handleAgeSelectionWhats,
    // setSelectedAgeRangeWhats,
    setWhatsMessageType,
    handleWhatsMessageChange,
    handleImageWhatsUpload,
    imageWhatsFileName,
    removeWhatsImage,
    handlePdfWhatsUpload,
    pdfWhatsFileName,
    removeWhatsPdf,
    isWhatsLoading,
    handleVideoWhatsUpload,
    videoWhatsFileName,
    removeWhatsVideo,
  } = useSendWhatsapp();

  const {
    emailModalShow,
    setEmailModalShow,
    sendEmail,
    selectedEmailTarget,
    targetEmailOptions,
    setSelectedEmailTarget,
    handleTextChange,
    text,
    isEmailLoading,
    setWillSendEmail,
    willSendEmail,
    canSubmitEmail,
    subject,
    imageFileName,
    pdfFileName,
    companyTarget,
    setCompanyTarget,
    handleImageUpload,
    removeImage,
    handlePdfUpload,
    removePdf,
    handleSubjectChange,
  } = useSendEmail();

  return (
    <div style={{ maxHeight: '80vh', overflow: 'auto'}}>
      <Loader isLoading={isLoading || isEmailLoading || isWhatsLoading} />

      <MyModal
        show={modalShow}
        onClose={() => {
          setModalShow(false);
          setWillSend(false);
        }}
        onAction={willSend ? sendPushNotification : () => setWillSend(true)}
        isActionButtonDisabled={isScheduled ? (!selectedTarget.value || !notificationMessage || !date || !canSubmit) : (!selectedTarget.value || !notificationMessage)}
        title="Enviar notificação"
        type="action"
        closeButtonLabel="Fechar"
        actionButtonLabel={willSend ? 'Confirmar envio' : isScheduled ? 'Agendar notificação' : 'Enviar notificação'}
        minHeight="230px"
        modalBody={(
          <>
          <FilterRadioButtonsContainer style={{ marginBottom: '16px'}}>
            <FilterRadioButton selected={!isScheduled} onClick={() => setIsScheduled(false)}>
              Enviar agora
            </FilterRadioButton>
            <FilterRadioButton selected={isScheduled} onClick={() => setIsScheduled(true)}>
              Agendar envio
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
            <FormGroup>
              <label htmlFor="contactName">Selecione o público alvo ou usuário específico</label>
              <Select
                value={
                      {
                        value: selectedTarget.value,
                        label: selectedTarget.label,
                      }
                    }
                options={targetOptions}
                onChange={(opt) => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  setSelectedTarget({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>

            <Group>
              <header>
                Idade crianças:
                {' '}
                {(selectedAgeRange[1] === 13 && selectedAgeRange[0] === 0) ? 'Todas' : (
                  `${selectedAgeRange[0]} - ${selectedAgeRange[1]} anos`
                )}
              </header>
              <RangeSlider
                min={0}
                max={13}
                value={selectedAgeRange}
                onThumbDragEnd={() => handleAgeSelection(selectedAgeRange)}
                onInput={(evt: number[]) => setSelectedAgeRange(evt)}
                className="single-thumb"
              />
            </Group>

            {isScheduled && (
              <FormGroup>
                <label htmlFor="contactName">Selecione a data e hora</label>
                <Input
                  type='datetime-local'
                  value={date}
                  onChange={(event) => handleScheduledDateChange(event)}
                />
              </FormGroup>
            )}

            <FormGroup>
              <label htmlFor="contactName">Escreva a mensagem</label>
              <Textarea
                placeholder="Escreva aqui a mensagem a ser enviada"
                onChange={(event) => handleNotificationMessageChange(event)}
                value={notificationMessage}
              />
            </FormGroup>
          </>
)}
      />

      <MyModal
        show={whatsModalShow}
        onClose={() => {
          setWhatsModalShow(false);
          setWillSendWhats(false);
        }}
        onAction={willSendWhats ? sendWhats : () => setWillSendWhats(true)}
        isActionButtonDisabled={!selectedTargetWhats.value || !whatsMessage || (whatsMessageType === 'image' && !imageWhatsFileName) || (whatsMessageType === 'pdf' && !pdfWhatsFileName) || (whatsMessageType === 'video' && !videoWhatsFileName)}
        title="Enviar Whatsapp"
        type="action"
        closeButtonLabel="Fechar"
        actionButtonLabel={willSendWhats ? 'Confirmar envio' : 'Enviar WhatsApp'}
        minHeight="230px"
        modalBody={(
          <>
            <FormGroup>
              <label htmlFor="contactName">Selecione o público alvo ou usuário específico</label>
              <Select
                value={
                      {
                        value: selectedWhatsTarget.value,
                        label: selectedWhatsTarget.label,
                      }
                    }
                options={targetWhatsOptions}
                onChange={(opt) => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  setSelectedWhatsTarget({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>

            <FilterRadioButtonsContainer>
              <FilterRadioButton selected={whatsMessageType === 'text'} onClick={() => setWhatsMessageType('text')}>
                Apenas Texto
              </FilterRadioButton>
              <FilterRadioButton selected={whatsMessageType === 'pdf'} onClick={() => setWhatsMessageType('pdf')}>
                PDF + texto
              </FilterRadioButton>
              <FilterRadioButton selected={whatsMessageType === 'image'} onClick={() => setWhatsMessageType('image')}>
                Imagem + texto
              </FilterRadioButton>
              <FilterRadioButton selected={whatsMessageType === 'video'} onClick={() => setWhatsMessageType('video')}>
                Vídeo + texto
              </FilterRadioButton>
            </FilterRadioButtonsContainer>

            <FormGroup>
              <label htmlFor="contactName">Escreva a mensagem</label>
              <Textarea
                placeholder="Escreva aqui a mensagem a ser enviada"
                onChange={(event) => handleWhatsMessageChange(event)}
                value={whatsMessage}
              />
            </FormGroup>

          {whatsMessageType === 'image' && (
            <GroupFileInput>
              <label htmlFor="contactName">Selecione a imagem</label>
              <FileInput
                onFileUpload={handleImageWhatsUpload}
                fileNameChoosed={imageWhatsFileName}
                acceptedFiles="image/*"
                hasSubmitAction={false}
                removeFile={removeWhatsImage}
              />
            </GroupFileInput>
          )}

          {whatsMessageType === 'video' && (
            <GroupFileInput>
              <label htmlFor="contactName">Selecione o vídeo</label>
              <FileInput
                onFileUpload={handleVideoWhatsUpload}
                fileNameChoosed={videoWhatsFileName}
                acceptedFiles="video/*"
                hasSubmitAction={false}
                removeFile={removeWhatsVideo}
              />
            </GroupFileInput>
          )}

          {whatsMessageType === 'pdf' && (
            <GroupFileInput>
              <label htmlFor="contactName">Selecione o pdf (opcional)</label>
              <FileInput
                onFileUpload={handlePdfWhatsUpload}
                fileNameChoosed={pdfWhatsFileName}
                acceptedFiles=".pdf"
                hasSubmitAction={false}
                removeFile={removeWhatsPdf}
              />
            </GroupFileInput>
          )}
          </>
)}
      />

      <MyModal
        show={emailModalShow}
        onClose={() => {
          setEmailModalShow(false);
          setWillSendEmail(false);
        }}
        onAction={willSendEmail ? sendEmail : () => setWillSendEmail(true)}
        isActionButtonDisabled={!canSubmitEmail}
        title="Enviar e-mail"
        type="action"
        closeButtonLabel="Fechar"
        actionButtonLabel={willSendEmail ? 'Confirmar envio' : 'Enviar E-mail'}
        minHeight="230px"
        modalBody={(
          <>
            <FormGroup>
              <label htmlFor="contactName">Selecione o público alvo ou usuário específico</label>
              <Select
                value={
                      {
                        value: selectedEmailTarget.value,
                        label: selectedEmailTarget.label,
                      }
                    }
                options={targetEmailOptions}
                onChange={(opt) => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  setSelectedEmailTarget({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>

            {isValidMongoId(selectedEmailTarget.value) && (
              <FilterRadioButtonsContainer style={{ marginBottom: '16px'}}>
                <FilterRadioButton selected={companyTarget === 'all'} onClick={() => setCompanyTarget('all')}>
                  Toda a Base
                </FilterRadioButton>
                <FilterRadioButton selected={companyTarget === 'subscribers'} onClick={() => setCompanyTarget('subscribers')}>
                  Assinantes
                </FilterRadioButton>
                <FilterRadioButton selected={companyTarget === 'users'} onClick={() => setCompanyTarget('users')}>
                  Cadastrados
                </FilterRadioButton>
                <FilterRadioButton selected={companyTarget === 'notRegistered'} onClick={() => setCompanyTarget('notRegistered')}>
                  Não Cadastrados
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
           )}

            <FormGroup>
              <label htmlFor="contactName">Assunto</label>
              <Input
                placeholder="Digite o assunto desse e-mail"
                onChange={(event) => handleSubjectChange(event)}
                value={subject}
                type='text'
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="contactName">Texto (título) do e-mail</label>
              <Textarea
                placeholder="Escreva uma mensagem para ser o título do e-mail"
                onChange={(event) => handleTextChange(event)}
                value={text}
              />
            </FormGroup>

            <GroupFileInput>
              <label htmlFor="contactName">Selecione a imagem (aparecerá no corpo do e-mail e no anexo)</label>
              <FileInput
                onFileUpload={handleImageUpload}
                fileNameChoosed={imageFileName}
                acceptedFiles="image/png"
                hasSubmitAction={false}
                removeFile={removeImage}
              />
            </GroupFileInput>

            <GroupFileInput>
              <label htmlFor="contactName">Selecione o pdf (será apenas anexado)</label>
              <FileInput
                onFileUpload={handlePdfUpload}
                fileNameChoosed={pdfFileName}
                acceptedFiles=".pdf"
                hasSubmitAction={false}
                removeFile={removePdf}
              />
            </GroupFileInput>
          </>
)}
      />

      {active === 'Dashboard'
        ? (
          <ActiveItem>
            <Link to="/">
              <MenuItem>
                <img src={chartPie} alt="dashboard" />
                Dashboard
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/">
            <MenuItem>
              <img src={chartPie} alt="dashboard" />
              Dashboard
            </MenuItem>
          </Link>
        )}

      {active === 'Forum'
        ? (
          <ActiveItem>
            <Link to="/forum">
              <MenuItem>
                <img src={comments} alt="forum" />
                Forum
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forum">
            <MenuItem>
              <img src={comments} alt="forum" />
              Forum
            </MenuItem>
          </Link>
        )}

      {active === 'ForumInternal'
        ? (
          <ActiveItem>
            <Link to="/forumInternal">
              <MenuItem>
                <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
                Forum interno
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forumInternal">
            <MenuItem>
              <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
              Forum interno
            </MenuItem>
          </Link>
        )}

      {active === 'ChangePassword'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={lock} alt="lock" />
                Alterar senha
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/changePassword">
            <MenuItem>
              <img src={lock} alt="lock" />
              Alterar senha
            </MenuItem>
          </Link>
        )}

      {active === 'Subscribers'
        ? (
          <ActiveItem>
            <Link to="/subscribers">
              <MenuItem>
                <img src={subscribers} alt="subscribers" />
                Assinantes
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/subscribers">
            <MenuItem>
              <img src={subscribers} alt="subscribers" />
              Assinantes
            </MenuItem>
          </Link>
        )}

      {active === 'Activities'
        ? (
          <ActiveItem>
            <Link to="/activities">
              <MenuItem>
                <img src={activity} alt="activity" />
                Atividades
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/activities">
            <MenuItem>
              <img src={activity} alt="activity" />
              Atividades
            </MenuItem>
          </Link>
        )}

      {active === 'Approve'
        ? (
          <ActiveItem>
            <Link to="/activitiesApprove">
              <MenuItem>
                <img src={doubleCheck} alt="activity" />
                Aprovações de atividades
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/activitiesApprove">
            <MenuItem>
              <img src={doubleCheck} alt="activity" />
              Aprovações de atividades
            </MenuItem>
          </Link>
        )}

      {active === 'Schedules'
        ? (
          <ActiveItem>
            <Link to="/schedules">
              <MenuItem>
                <img src={calendar} alt="schedule" />
                Aulas/Agendamentos
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/schedules">
            <MenuItem>
              <img src={calendar} alt="schedule" />
              Aulas/Agendamentos
            </MenuItem>
          </Link>
        )}

      {active === 'GivenClasses'
        ? (
          <ActiveItem>
            <Link to="/givenClasses">
              <MenuItem>
                <img src={givenClass} alt="class" />
                Aulas Realizadas
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/givenClasses">
            <MenuItem>
              <img src={givenClass} alt="class" />
              Aulas Realizadas
            </MenuItem>
          </Link>
        )}

      {active === 'Books'
        ? (
          <ActiveItem>
            <Link to="/books">
              <MenuItem>
                <img src={book} alt="book" />
                Livros
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/books">
            <MenuItem>
              <img src={book} alt="book" />
              Livros
            </MenuItem>
          </Link>
        )}

      {active === 'Cupons'
        ? (
          <ActiveItem>
            <Link to="/cupons">
              <MenuItem>
                <img src={cupom} alt="cupom" />
                Cupons
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/cupons">
            <MenuItem>
              <img src={cupom} alt="cupom" />
              Cupons
            </MenuItem>
          </Link>
        )}

      {active === 'DisabledDates'
        ? (
          <ActiveItem>
            <Link to="/disabledDates">
              <MenuItem>
                <img src={calendarX} alt="calendarX" />
                Datas desabilitadas (geral)
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/disabledDates">
            <MenuItem>
              <img src={calendarX} alt="calendarX" />
              Datas desabilitadas (geral)
            </MenuItem>
          </Link>
        )}

      {active === 'Highlights'
        ? (
          <ActiveItem>
            <Link to="/highlights">
              <MenuItem>
                <img src={star} alt="star" />
                Gerenciar Destaques App
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/highlights">
            <MenuItem>
              <img src={star} alt="star" />
              Gerenciar Destaques App
            </MenuItem>
          </Link>
        )}

      {active === 'Companies'
        ? (
          <ActiveItem>
            <Link to="/companies">
              <MenuItem>
                <img src={company} alt="schedule" />
                Empresas (B2B/Distribuidor)
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/companies">
            <MenuItem>
              <img src={company} alt="schedule" />
              Empresas (B2B/Distribuidor)
            </MenuItem>
          </Link>
        )}

      {active === 'Financial'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={financial} alt="financial" />
                Financeiro
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/financial">
            <MenuItem>
              <img src={financial} alt="financial" />
              Financeiro
            </MenuItem>
          </Link>
        )}

      {active === 'Partners'
        ? (
          <ActiveItem>
            <Link to="/partners">
              <MenuItem>
                <img src={handshake} alt="handshake" />
                Parceiros
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/partners">
            <MenuItem>
              <img src={handshake} alt="handshake" />
              Parceiros
            </MenuItem>
          </Link>
        )}

      {active === 'Users'
        ? (
          <ActiveItem>
            <Link to="/users">
              <MenuItem>
                <img src={user} alt="user" />
                Usuários
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/users">
            <MenuItem>
              <img src={user} alt="user" />
              Usuários
            </MenuItem>
          </Link>
        )}

      {active === 'InternalUsers'
        ? (
          <ActiveItem>
            <Link to="/internalUsers">
              <MenuItem>
                <img src={userTie} alt="user" />
                Usuários (internos)
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/internalUsers">
            <MenuItem>
              <img src={userTie} alt="user" />
              Usuários (internos)
            </MenuItem>
          </Link>
        )}

      <MenuItem onClick={() => setModalShow(true)}>
        <img src={bell} alt="push" />
        Enviar notificação (push)
      </MenuItem>

      {active === 'CancelPush'
        ? (
          <ActiveItem>
            <Link to="/cancelPush">
              <MenuItem>
                <img src={bellSlash} alt="class" />
                Cancelar Pushs Agendados
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/cancelPush">
            <MenuItem>
              <img src={bellSlash} alt="class" />
              Cancelar Pushs Agendados
            </MenuItem>
          </Link>
        )}

      <MenuItem onClick={() => setEmailModalShow(true)}>
        <img src={email} alt="push" />
        Enviar E-mail
      </MenuItem>

      <div style={{ display: 'none' }}></div>

      <MenuItem onClick={() => setWhatsModalShow(true)}>
        <img src={whatsapp} alt="push" />
        Enviar WhatsApp
      </MenuItem>
    </div>
  );
}
