import { ChangeEvent } from 'react';
import FileInputWithRemove from '../../../../../components/FileInputWithRemove';
import { Container as StyledContainer } from '../../../../../components/Form';
import FormGroup from '../../../../../components/FormGroup';
import { BookTypeType } from '../../../types';

interface IFilesCard {
  handleEpubUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handleRemoveEpub: () => void;
  epubFileName: string;
  downloadHtml?: () => void;
  downloadEpub?: () => void;
  type: BookTypeType;
}

export default function FilesCard({
  type,
  handleEpubUpload,
  handleRemoveEpub,
  epubFileName,
  downloadEpub,
}: IFilesCard) {
  return (
    <StyledContainer>
      <div className="card-title">
        Arquivos
      </div>

        <FormGroup>
          <label htmlFor="login">Livro EPUB *</label>
          <FileInputWithRemove
              onFileUpload={handleEpubUpload}
              fileNameChoosed={epubFileName}
              acceptedFiles=".epub"
              hasSubmitAction={false}
              removeFile={handleRemoveEpub}
              downloadFile={downloadEpub}
              disabled={type === 'external'}
            />
        </FormGroup>
    </StyledContainer>
  );
}
