import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../components/Modal';
import { BookChangingStatusType } from '../types';

interface UpdateActivityStatusInterface {
  bookId: string;
  active: boolean;
}

interface UpdateStatusModal {
  bookChangingStatus: BookChangingStatusType;
  setChangeStatusModalShow: Dispatch<SetStateAction<boolean>>;
  updateBookStatus: ({bookId, active}: UpdateActivityStatusInterface) => void;
  changeStatusModalShow: boolean;
}

export default function UpdateStatusModal({
  bookChangingStatus,
  setChangeStatusModalShow,
  updateBookStatus,
  changeStatusModalShow,
}: UpdateStatusModal) {
  return (
    <MyModal
      title={bookChangingStatus.status === 'Ativo' ? `Inativar atividade ${bookChangingStatus.name}` : `Reativar atividade ${bookChangingStatus.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel={bookChangingStatus.status === 'Ativo' ? 'Inativar' : 'Reativar'}
      modalBody={(
        <div>
          {bookChangingStatus.status === 'Ativo' ? 'Tem certeza que deseja inativar a atividade' : 'Tem certeza que deseja reativar a atividade'}
          {' '}
          <strong>{bookChangingStatus.name}</strong>
          {' '}
          ?
        </div>
          )}
      onClose={() => setChangeStatusModalShow(false)}
      onAction={() => updateBookStatus({
        bookId: bookChangingStatus.id,
        active: bookChangingStatus.status !== 'Ativo',
      })}
      show={changeStatusModalShow}
      type={bookChangingStatus.status === 'Ativo' ? 'suspendAction' : 'activateAction'}
    />
  );
}
