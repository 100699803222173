import { useState } from 'react';
import MyModal from '../../../components/Modal';

interface IActivationDateModal {
  modalSpecificActivationDateShow: boolean;
  handleSpecificActivationDateChange: (date: string) => void;
  handleModalSpecificActivationDateShow: (show: boolean) => void;
}

export default function ActivationDateModal({
  modalSpecificActivationDateShow,
  handleModalSpecificActivationDateShow,
  handleSpecificActivationDateChange,
}: IActivationDateModal) {
  const [selectedDate, setSelectedDate] = useState<string>('');

  return (
    <MyModal
      show={modalSpecificActivationDateShow}
      title="Selecionar data de ativação"
      centeredBody
      modalBody={(
        <input type="date" value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value) }} />
          )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Confirmar"
      onClose={() => handleModalSpecificActivationDateShow(false)}
      onAction={() => {
        handleSpecificActivationDateChange(selectedDate);
        handleModalSpecificActivationDateShow(false);
      }}
      isActionButtonDisabled={!selectedDate}
      type="action"
      size="sm"
    />
  );
}
