import { Dispatch, SetStateAction } from "react";
import FormGroup from "../../../../../components/FormGroup";
import Input from "../../../../../components/Input";
import MyModal from "../../../../../components/Modal";
import OpacityAnimation from "../../../../../components/OpacityAnimation";
import { Group, ModalContainer } from "./styles";
import useAddModal from "./useAddModal";

interface IAddModal {
  addModalShow: boolean;
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
  loadCategories: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function AddModal({
  addModalShow,
  setAddModalShow,
  loadCategories,
  setIsLoading,
}: IAddModal) {
  const {
    name,
    getErrorMessageByFieldName,
    handleNameChange,
    addCategory,
    isFormValid,
  } = useAddModal({
    loadCategories,
    setIsLoading,
    setAddModalShow,
  });

  return (
    <MyModal
    show={addModalShow}
    onClose={() => setAddModalShow(false)}
    title={`Adicionar categoria`}
    modalBody={(
      <ModalContainer>
        <OpacityAnimation delay={0.1}>
          <Group>
            <div className="title">
              Nome da categoria
            </div>
            <FormGroup error={getErrorMessageByFieldName('name')}>
              <Input
                error={getErrorMessageByFieldName('name')}
                value={name}
                onChange={handleNameChange}
                placeholder="Nome da categoria"
              />
            </FormGroup>
          </Group>
        </OpacityAnimation>
      </ModalContainer>
            )}
    closeButtonLabel="Fechar"
    size="lg"
    type='action'
    actionButtonLabel="Adicionar"
    isActionButtonDisabled={!isFormValid}
    onAction={() => addCategory()}
  />
  )
}
