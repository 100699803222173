import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import booksService from '../../../../services/booksService';
import { BookBeingDeletedType } from '../../types';

interface IUseDeleteBook {
  resetFields?: () => void;
  loadBooks: () => void;
}

interface HandleOpenDeleteModalInterface {
  name: string;
  id: string;
}

export default function useDeleteBook({
  // resetFields,
  loadBooks,
}: IUseDeleteBook) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [bookBeingDeleted, setBookBeingDeleted] = useState({} as BookBeingDeletedType);

  const { apiCall } = useApiCall();

  const deleteBook = useCallback(async (bookid: string) => {
    await apiCall({
        apiToCall: booksService.deleteBook,
        queryParams: { id: bookid },
        onStartLoad: () => setIsDeleting(true),
        onEndLoad: () => {
          setIsDeleting(false);
          setDeleteModalShow(false);
        },
        actionAfterResponse: (apiResponse) => {
          if (!apiResponse.success) {
            toast.error('Não foi possível deletar o livro. Por favor, tente novamente', {
              toastId: 'deleteBookFail',
            });
            return;
          }
          toast.success('O livro foi removido com sucesso!', {
            toastId: 'deleteBookSuccess',
          });
          // resetFields();
          loadBooks();
        },
        catchMessage: 'Não foi possível deletar o livro, Por favor, tente novamente',
    })
  }, [apiCall, loadBooks]);

  function handleOpenDeleteModal({ name, id }: HandleOpenDeleteModalInterface) {
    setDeleteModalShow(true);
    setBookBeingDeleted({
      name,
      id,
    });
  }

  return {
    isDeleting,
    deleteModalShow,
    bookBeingDeleted,
    deleteBook,
    handleOpenDeleteModal,
    setDeleteModalShow,
  };
}
