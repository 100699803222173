import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import booksService from '../../../../services/booksService';
import partnersService from '../../../../services/partnersService';
import splitArray from '../../../../utils/splitArray';
import { BooksListFromApiType, BookType, CategoriesFromApiResponse, PartnersFromApiResponse } from '../../types';

interface IUseLoadBooks {
  setCurrentPage: Dispatch<SetStateAction<number>>
}

export default function useLoadBooks({
  setCurrentPage,
}: IUseLoadBooks) {
  const [isLoadLoading, setIsLoadLoading] = useState(true);
  const [fullBooks, setFullBooks] = useState<BookType[]>([]);
  const [booksSplited, setBooksSplited] = useState<Array<BookType[]>>([]);
  const [filteredBooks, setFilteredBooks] = useState<BookType[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [categoriesList, setCategoriesList] = useState([{ value: '', label: 'Todas' }]);
  const [partnersList, setPartnersList] = useState([{ value: '', label: 'Todos' }]);

  const { apiCall } = useApiCall();

  const loadBooks = useCallback(async () => {
    await apiCall({
      apiToCall: booksService.getBooksList,
      actionAfterResponse: (apiResponse: BooksListFromApiType) => {
        const booksListFromApi = apiResponse.list;
        if (!booksListFromApi) {
          toast.error(`Não foi possível carregar a lista de livros`, {
            toastId: 'loadBooksFail',
          });
          setDoesListApiHasError(true);
          return;
        }
        const booksList = booksListFromApi;
        setFullBooks(booksList);

        const splitedArray = splitArray(booksList);
        setBooksSplited(splitedArray);
        setFilteredBooks(splitedArray[0]);
        setCurrentPage(0);
        setDoesListApiHasError(false);
      },
      catchMessage: 'Não foi possível carregar a lista de livros',
    })
  }, [apiCall, setCurrentPage]);

  const getCategoriesList = useCallback(async () => {
    await apiCall({
      apiToCall: booksService.getCategories,
      actionAfterResponse: (apiResponse) => {
        const categoriesListFromApi: CategoriesFromApiResponse = apiResponse.categories;
        const categoriesOptions = categoriesListFromApi.map((ctg) => ({ value: ctg.id, label: ctg.name }));
        categoriesOptions.unshift({
          value: '',
          label: 'Todas',
        });

        setCategoriesList(categoriesOptions);
      },
      catchMessage: 'Não foi possível carregar as categorias para utilizar os filtros. Por favor, tente novamente',
    })
  }, [apiCall]);

  const getPartnersList = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.getResumedPartnersList,
      actionAfterResponse: (apiResponse) => {
        const partnersListFromApi: PartnersFromApiResponse = apiResponse.list;
        const mappedPartners = partnersListFromApi.map((partner) => ({
          value: partner.id, label: partner.nome_fantasia,
        }));
        setPartnersList(mappedPartners);
      },
      catchMessage: 'Não foi possível carregar a lista de parceiros para utilizar o filtro. Por favor, tente novamente',
    })
  }, [apiCall]);

  const loadPage = useCallback(async () => {
    try {
      setIsLoadLoading(true);
      await Promise.all([
        loadBooks(),
        getCategoriesList(),
        getPartnersList(),
      ]);
    } catch (error) {
      toast.error(`Não foi possível carregar a lista de livros (${error})`);
    } finally {
      setIsLoadLoading(false);
    }
  }, [getCategoriesList, getPartnersList, loadBooks]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return {
    isLoadLoading,
    fullBooks,
    doesListApiHasError,
    filteredBooks,
    setFilteredBooks,
    loadBooks,
    categoriesList,
    partnersList,
    booksSplited,
    setBooksSplited,
    loadPage,
  };
}
