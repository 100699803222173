import { useCallback, useMemo, useState } from "react";
import useDeleteBook from "./functions/useDeleteBook";
import useLoadBooks from "./functions/useLoadBooks";
import useSearches from "./functions/useSearches";
import useUpdateBookStatus from "./functions/useUpdateBookStatus";

export default function useBooks() {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoadLoading,
    fullBooks,
    doesListApiHasError,
    filteredBooks,
    setFilteredBooks,
    loadBooks,
    categoriesList,
    partnersList,
    booksSplited,
    setBooksSplited,
    loadPage,
  } = useLoadBooks({ setCurrentPage });

  const {
    searchTerm,
    handleChangeSearchTerm,
    selectedCategory,
    selectedPartner,
    selectedStatus,
    setSelectedStatus,
    selectedType,
    handlePartnerChange,
    handleCategoryChange,
    handleStatusChange,
    handleTypeChange,
    setSearchTerm,
    setSelectedCategory,
    setSelectedPartner,
  } = useSearches({
    fullBooks,
    setBooksSplited,
    setFilteredBooks,
  });

  const resetFields = useCallback(() => {
    setSearchTerm('');
    setSelectedCategory({ value: '', label: 'Filtrar por categoria' });
    setSelectedPartner({ value: '', label: 'Filtrar por parceiro' });
    setSelectedStatus('');
  }, [setSearchTerm, setSelectedPartner, setSelectedCategory, setSelectedStatus]);

  const {
    isDeleting,
    deleteModalShow,
    bookBeingDeleted,
    deleteBook,
    handleOpenDeleteModal,
    setDeleteModalShow,
  } = useDeleteBook({
    resetFields,
    loadBooks,
  });

  const {
    isUpdatingStatus,
    changeStatusModalShow,
    setChangeStatusModalShow,
    bookChangingStatus,
    updateBookStatus,
    handleOpenChangeStatusModal,
  } = useUpdateBookStatus({ loadBooks });

  const pagesQuantity = useMemo(() => booksSplited.length, [booksSplited.length]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredBooks(booksSplited[page]);
  }

  function handleTryAgain() {
    loadPage();
  }

  const isLoading = isLoadLoading || isUpdatingStatus || isDeleting;

  return {
    isLoading,
    fullBooks,
    categoriesList,
    selectedCategory,
    partnersList,
    selectedPartner,
    selectedStatus,
    handleStatusChange,
    handlePartnerChange,
    handleCategoryChange,
    handleTypeChange,
    selectedType,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    handlePageChange,
    pagesQuantity,
    currentPage,
    filteredBooks,
    handleOpenDeleteModal,
    handleOpenChangeStatusModal,
    bookBeingDeleted,
    setDeleteModalShow,
    deleteBook,
    deleteModalShow,
    bookChangingStatus,
    setChangeStatusModalShow,
    updateBookStatus,
    changeStatusModalShow,
    handleTryAgain,
    resetFields,
  }
}
