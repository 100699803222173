import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../../hooks/useApiCall";
import useErrors from "../../../../../hooks/useErrors";
import booksService from "../../../../../services/booksService";

interface IUseAddModal {
  loadCategories: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function useAddModal({
  loadCategories,
  setIsLoading,
  setAddModalShow
}: IUseAddModal) {
  const [name, setName] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome da categoria é obrigatório!' })
    } else {
      removeError('name')
    }
  }

  const addCategory = useCallback(async () => {
    await apiCall({
      apiToCall: booksService.createCategory,
      onStartLoad: () => { setIsLoading(true) },
      queryParams: { name: encodeURIComponent(name) },
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Categoria adicionada com sucesso!');
          setAddModalShow(false);
          setName('');
          loadCategories();
          return;
        }
        toast.error('Não foi possível adicionar a categoria. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível adicionar a categoria. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, loadCategories, name, setAddModalShow, setIsLoading]);

  const isFormValid = !!name && errors.length === 0;

  return {
    name,
    getErrorMessageByFieldName,
    handleNameChange,
    addCategory,
    isFormValid,
  }
}
