/* eslint-disable max-len */
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import splitArray from '../../../../utils/splitArray';
import { BookType } from '../../types';

interface UseSearchesInterface {
  fullBooks: BookType[];
  setBooksSplited: Dispatch<SetStateAction<Array<BookType[]>>>;
  setFilteredBooks: Dispatch<SetStateAction<BookType[]>>;
}

export default function useSearches({
  fullBooks,
  setBooksSplited,
  setFilteredBooks,
}: UseSearchesInterface) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState({
    value: '',
    label: 'Filtrar por categoria',
  });
  const [selectedPartner, setSelectedPartner] = useState({
    value: '',
    label: 'Filtrar por parceiro',
  });
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedType, setSelectedType] = useState<'all' | 'internal' | 'external'>('all');

  const filterBooks = useCallback(() => {
    const filteredBooks = fullBooks.filter((bk) => {
      const categoryFilter = !selectedCategory.value || bk.category.id === selectedCategory.value;
      const partnerFilter = !selectedPartner.value || bk.partner.id === selectedPartner.value;
      const statusFilter = !selectedStatus || (bk.active && selectedStatus === 'Ativo') || (!bk.active && selectedStatus === 'Inativo');
      const searchFilter = bk.title.toLowerCase().includes(String(searchTerm).toLowerCase());
      const typeFilter = selectedType === 'all' || bk.type === selectedType;

      return categoryFilter && partnerFilter && statusFilter && searchFilter && typeFilter;
    });

    const booksSplitedbyFilters = splitArray(filteredBooks);
    setBooksSplited(booksSplitedbyFilters);
    setFilteredBooks(booksSplitedbyFilters[0]);
  }, [fullBooks, setBooksSplited, setFilteredBooks, selectedCategory.value, selectedPartner.value, selectedStatus, searchTerm, selectedType]);

  useEffect(() => {
    filterBooks();
  }, [filterBooks]);

  const handleCategoryChange = useCallback((category: { value: string, label: string }) => {
    setSelectedCategory(category);
  }, []);

  const handlePartnerChange = useCallback((partner: { value: string, label: string }) => {
    setSelectedPartner(partner);
  }, []);

  const handleStatusChange = useCallback((status: string) => {
    setSelectedStatus(status);
  }, []);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleTypeChange = useCallback((kind: 'all' | 'internal' | 'external') => {
    setSelectedType(kind);
  }, []);

  return {
    searchTerm,
    handleChangeSearchTerm,
    selectedCategory,
    selectedPartner,
    selectedStatus,
    setSelectedStatus,
    handlePartnerChange,
    handleCategoryChange,
    handleStatusChange,
    setSearchTerm,
    setSelectedCategory,
    setSelectedPartner,
    handleTypeChange,
    selectedType,
  };
}
