import useDeleteUser from './functions/useDeleteUser';
import useDownloadExcel from './functions/useDownloadExcel';
import useLoadUsers from './functions/useLoadUsers';
import usePagination from './functions/usePagination';
import useSearches from './functions/useSearches';

export default function useActivities() {
  const {
    currentPage,
    setCurrentPage,
    filteredUsers,
    setFilteredUsers,
    setUsersSplitted,
    pagesQuantity,
    handlePageChange,
  } = usePagination();

  const {
    isLoadLoading,
    users,
    usersFullData,
    companysList,
    doesListApiHasError,
    loadUsers,
    statusOptions,
  } = useLoadUsers({
    setUsersSplitted,
    setCurrentPage,
    setFilteredUsers,
  });

  const {
    searchTerm,
    setSearchTerm,
    handleChangeSearchTerm,
    selectedCompany,
    handleCompanyChange,
    handleStatusChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
    selectedStatus,
    handleIsSpecificRegistrationDate,
    isSpecificRegistrationDate,
    handleModalSpecificRegistrationDateShow,
    registrationDateBeingShown,
    handleIsSpecificActivationDate,
    isSpecificActivationDate,
    handleModalSpecificActivationDateShow,
    activationDateBeingShown,
    modalSpecificRegistrationDateShow,
    modalSpecificActivationDateShow,
    handleSpecificRegistrationDateChange,
    handleSpecificActivationDateChange,
  } = useSearches({
    users,
    setUsersSplitted,
    setFilteredUsers,
  });

  const {
    isDeletingUser,
    deleteModalShow,
    setDeleteModalShow,
    userBeingDeleted,
    deleteUser,
    handleOpenDeleteModal,
  } = useDeleteUser({
    loadUsers,
    setSearchTerm,
  });

  const { downloadExcel, downloadRatings } = useDownloadExcel({ usersFullData });

  function handleTryAgain() {
    loadUsers();
  }

  const isLoading = isLoadLoading || isDeletingUser;

  return {
    isLoading,
    users,
    searchTerm,
    handleChangeSearchTerm,
    selectedCompany,
    handleCompanyChange,
    companysList,
    doesListApiHasError,
    filteredUsers,
    handleTryAgain,
    pagesQuantity,
    currentPage,
    handlePageChange,
    handleOpenDeleteModal,
    userBeingDeleted,
    setDeleteModalShow,
    deleteUser,
    deleteModalShow,
    downloadExcel,
    downloadRatings,
    handleStatusChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
    statusOptions,
    selectedStatus,
    handleIsSpecificRegistrationDate,
    isSpecificRegistrationDate,
    handleModalSpecificRegistrationDateShow,
    registrationDateBeingShown,
    handleIsSpecificActivationDate,
    isSpecificActivationDate,
    handleModalSpecificActivationDateShow,
    activationDateBeingShown,
    modalSpecificRegistrationDateShow,
    modalSpecificActivationDateShow,
    handleSpecificRegistrationDateChange,
    handleSpecificActivationDateChange,
  };
}
