import { Col, Container, Row } from "react-bootstrap";
import Button from "../../../../components/Button";
import FilePreview from "../../../../components/FilePreview";
import { ButtonContainer } from "../../../../components/Form";
import Loader from "../../../../components/Loader";
import OpacityAnimation from "../../../../components/OpacityAnimation";
import PageHeader from "../../../../components/PageHeader";
import FilesCard from "./components/FilesCard";
import GeneralDataCard from "./components/GeneralDataCard";
import TypeOfBook from "./components/TypeOfBook";
import { Container as StyledContainer } from "./styles";
import useBookForm from "./useBookForm";

export default function BookForm({ edit }: { edit: boolean }) {
  const {
    isLoading,
    getErrorMessageByFieldName,
    title,
    handleTitleChange,
    externalBooksList,
    selectedExternalBook,
    handleSelectedExternalBookChange,
    type,
    author,
    handleAuthorChange,
    description,
    handleDescriptionChange,
    partnersList,
    partner,
    handlePartnerChange,
    categoriesList,
    category,
    handleCategoryChange,
    level,
    levelOptions,
    handleLevelChange,
    coverFile,
    setCoverFile,
    handleEpubUpload,
    handleRemoveEpub,
    epubFileName,
    downloadEpub,
    setType,
    canSubmit,
    updateBook,
    createBook,
  } = useBookForm();

  return (
    <>
      <Loader isLoading={isLoading} />
      {edit && (
        <PageHeader
          title={`Editar Livro ${title}`}
          link={'/books'}
        />
      )}

      <TypeOfBook setTypeOfBook={setType} typeOfBook={type} edit={edit} />

      <Container>
        <Row xs={1} md={1} lg={1}>
          <Col>
            <OpacityAnimation>
              <GeneralDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                title={title}
                handleTitleChange={handleTitleChange}
                externalBooksList={externalBooksList}
                selectedExternalBook={selectedExternalBook}
                handleSelectedExternalBookChange={handleSelectedExternalBookChange}
                type={type}
                author={author}
                handleAuthorChange={handleAuthorChange}
                description={description}
                handleDescriptionChange={handleDescriptionChange}
                partnersList={partnersList}
                partner={partner}
                handlePartnerChange={handlePartnerChange}
                categoriesList={categoriesList}
                category={category}
                handleCategoryChange={handleCategoryChange}
                level={level}
                levelOptions={levelOptions}
                handleLevelChange={handleLevelChange}
                edit={edit}
              />
            </OpacityAnimation>
          </Col>

          <Col>
            <StyledContainer>
              <div className="card-title">
                Foto *
              </div>
              <FilePreview
                selectedFile={coverFile || undefined}
                setSelectedFile={setCoverFile}
                fileType="image"
                noDataLabel="Por favor, escolha uma imagem para ser a capa (thumbnail) de seu livro. Essa será a imagem mostrada no aplicativo. OBRIGATÓRIO"
              />
            </StyledContainer>
          </Col>

          <FilesCard
            handleEpubUpload={handleEpubUpload}
            handleRemoveEpub={handleRemoveEpub}
            epubFileName={epubFileName}
            downloadEpub={downloadEpub}
            type={type}
          />
        </Row>

      <ButtonContainer>
        {edit && (
            <Button
              disabled={!canSubmit}
              onClick={() => updateBook()}
              // tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              // tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Salvar Livro ${title ?? ''}`}
            </Button>
          )}

          {!edit && (
            <Button
              disabled={!canSubmit}
              onClick={() => createBook()}
              // tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              // tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Criar Livro ${title ?? ''}`}
            </Button>
          )}
      </ButtonContainer>
      </Container>
    </>
  )
}
