import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../../hooks/useApiCall";
import useErrors from "../../../../../hooks/useErrors";
import booksService from "../../../../../services/booksService";
import { CategoryBeingEditedType } from "../../types";

interface UseEditModalI {
  loadCategories: () => Promise<void>;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  categoryBeingEditted: CategoryBeingEditedType;
}

export default function useEditModal({ loadCategories, setEditModalShow, setIsLoading, categoryBeingEditted }: UseEditModalI) {
  const [name, setName] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome da categoria é obrigatório!' })
    } else {
      removeError('name')
    }
  }

  const editCategory = useCallback(async () => {
    await apiCall({
      apiToCall: booksService.updateCategory,
      onStartLoad: () => { setIsLoading(true) },
      queryParams: { categoryId: categoryBeingEditted.id, name: encodeURIComponent(name) },
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Categoria editada com sucesso!');
          setEditModalShow(false);
          setName('');
          loadCategories();
          return;
        }
        toast.error('Não foi possível editada a categoria. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível editada a categoria. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, categoryBeingEditted.id, loadCategories, name, setEditModalShow, setIsLoading]);

  const isEditFormValid = !!name && errors.length === 0;

  useEffect(() => { setName(categoryBeingEditted.name) }, [categoryBeingEditted.name])

  return {
    editCategory,
    name,
    handleNameChange,
    getErrorMessageByFieldName,
    isEditFormValid,
  }
}
