import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../components/Modal';
import { BookBeingDeletedType } from '../types';

interface DeleteModalInterface {
  bookBeingDeleted: BookBeingDeletedType;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  deleteBook: (activityId: string) => void;
  deleteModalShow: boolean;
}

export default function DeleteModal({
  bookBeingDeleted,
  setDeleteModalShow,
  deleteBook,
  deleteModalShow,
}: DeleteModalInterface) {
  return (
    <MyModal
      title={`Excluir livro ${bookBeingDeleted.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Excluir"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja excluir o livro
            {' '}
            <strong>{bookBeingDeleted.name}</strong>
            {' '}
            ?
          </div>
          <br />
          <strong>Atenção, esta ação é irreversível!</strong>
        </>
          )}
      onClose={() => setDeleteModalShow(false)}
      onAction={() => deleteBook(bookBeingDeleted.id)}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
