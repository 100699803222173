import PageHeader from "../../components/PageHeader";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import BookForm from "./components/BookForm";

export default function NewBook() {
  return (
    <>
      <Sidebar active={'Books'} />
      <Transitions>
      <PageHeader
          title="Novo Livro"
          link="/books"
        />

        <BookForm edit={false} />
      </Transitions>
    </>
  );
}
