import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import booksService from '../../../../services/booksService';
import { BookChangingStatusType } from '../../types';

interface IUseUpdateBookStatus {
  loadBooks: () => void;
}

interface IUpdateBookStatus {
  bookId: string;
  active: boolean;
}

interface HandleOpenChangeStatusModalInterface {
  name: string;
  id: string;
  status: string;
}

export default function useUpdateBookStatus({
  loadBooks,
}: IUseUpdateBookStatus) {
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [bookChangingStatus, setBookChangingStatus] = useState({} as BookChangingStatusType);

  const { apiCall } = useApiCall();

  const updateBookStatus = useCallback(async ({ bookId, active }: IUpdateBookStatus) => {
    await apiCall({
      apiToCall: booksService.updateBookStatus,
      queryParams: { id: bookId, active },
      onStartLoad: () => setIsUpdatingStatus(true),
      onEndLoad: () => {
        setChangeStatusModalShow(false);
        setIsUpdatingStatus(false);
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível alterar o status do livro. Por favor, tente novamente', {
            toastId: 'updateBookFail',
          });
          return;
        }
        toast.success(`O livro foi ${!active ? 'inativado' : 'reativado'} com sucesso`, {
          toastId: 'updateBookSuccess',
        });
        setChangeStatusModalShow(false);
        loadBooks();
      },
      catchMessage: 'Não foi possível alterar o status do livro. Por favor, tente novamente',
    })
  }, [apiCall, loadBooks]);

  function handleOpenChangeStatusModal({ name, id, status }: HandleOpenChangeStatusModalInterface) {
    setChangeStatusModalShow(true);
    setBookChangingStatus({
      name,
      id,
      status,
    });
  }

  return {
    isUpdatingStatus,
    changeStatusModalShow,
    setChangeStatusModalShow,
    bookChangingStatus,
    updateBookStatus,
    handleOpenChangeStatusModal,
  };
}
