import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import activate from '../../../../assets/images/icons/activate.svg';
import calendarPlus from '../../../../assets/images/icons/calendarPlus.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import halfStar from '../../../../assets/images/icons/half-star.svg';
import personChalkboard from '../../../../assets/images/icons/person-chalkboard.svg';
import suspend from '../../../../assets/images/icons/suspend.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { MappedActivitiesListType } from '../../types';
import {
  Card,
} from './styles';

interface HandleOpenChangeStatusModalInterface {
  status: string;
  name: string;
  id: string;
}

interface HandleOpenDeleteModalInterface {
  name: string;
  id: string;
}

interface ListInterface {
  filteredList: MappedActivitiesListType[];
  handleOpenDeleteModal: ({ name, id }: HandleOpenDeleteModalInterface) => void;
  handleOpenChangeStatusModal: ({ name, id, status }: HandleOpenChangeStatusModalInterface) => void;
  downloadRatings: (activityId: string) => void;
}

export default function List({
  filteredList,
  handleOpenDeleteModal,
  handleOpenChangeStatusModal,
  downloadRatings,
}: ListInterface) {
  console.log(filteredList)
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((activity) => (
          <Col key={activity.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{activity.name}</strong>
                    <small className={activity.status !== 'Ativo' ? 'orange' : ''}>
                      {activity.status}
                    </small>
                  </div>
                  <span>
                    Categoria:
                    {' '}
                    {activity.generalData.category}
                  </span>
                  <span>
                    Subcategoria:
                    {' '}
                    {activity.generalData.subcategory}
                  </span>
                  <span>
                    {activity.generalData.subcategory === 'Educação parental' ? (
                      <>
                        Atividade direcionada para os pais
                      </>
                    ) : (
                      <>
                        Faixa etária:
                        {' '}
                        De
                        {' '}
                        {activity.generalData.ageRange[0]}
                        {' '}
                        a
                        {' '}
                        {activity.generalData.ageRange[1]}
                        {' '}
                        anos
                      </>
                    )}
                  </span>
                  <span>
                    Avaliação Média (1 a 5):
                    {' '}
                    {activity.ratingsAverage}
                  </span>
                  {/* <span>
                      {activity.generalData.kind}
                    </span> */}
                </div>

                <div className="actions">
                  <button
                    type="button"
                    onClick={() => handleOpenChangeStatusModal({
                      name: activity.name,
                      id: activity.id,
                      status: activity.status,
                    })}
                  >
                    {activity.status === 'Ativo' ? (
                      <img src={suspend} alt="suspend" title="Inativar Atividade" />
                    ) : (
                      <img src={activate} alt="trash" title="Reativar Atividade" />
                    )}
                  </button>

                  {(activity.generalData.kind !== 'Aula em vídeo' && activity.status === 'Ativo') && (
                    <>
                      <Link to={`/activities/${activity.id}/program`}>
                        <img src={calendarPlus} alt="program" title="Gerenciar programação específica" />
                      </Link>
                      <Link to={`/activities/${activity.id}/pedagogicalProgram`}>
                        <img src={personChalkboard} alt="program" title="Gerenciar programação pedagógica" />
                      </Link>
                    </>
                  )}

                  <Link to={`/activities/${activity.id}`}>
                    <img src={edit} alt="edit" title="Editar atividade" className="primaryColor" />
                  </Link>

                {activity.hasRatings && (
                  <button
                    type="button"
                    onClick={() => downloadRatings(activity.id)}
                  >
                    <img src={halfStar} alt="trash" title="Baixar XLSX de avaliações" />
                  </button>
                )}

                  <button
                    type="button"
                    onClick={() => handleOpenDeleteModal({
                      name: activity.name,
                      id: activity.id,
                    })}
                  >
                    <img src={trash} alt="trash" title="Excluir Atividade" />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
