import { useState } from 'react';
import MyModal from '../../../components/Modal';

interface IRegistrationDateModal {
  modalSpecificRegistrationDateShow: boolean;
  handleSpecificRegistrationDateChange: (date: string) => void;
  handleModalSpecificRegistrationDateShow: (show: boolean) => void;
}

export default function RegistrationDateModal({
  modalSpecificRegistrationDateShow,
  handleModalSpecificRegistrationDateShow,
  handleSpecificRegistrationDateChange,
}: IRegistrationDateModal) {
  const [selectedDate, setSelectedDate] = useState<string>('');

  return (
    <MyModal
      show={modalSpecificRegistrationDateShow}
      title="Selecionar data de cadastro"
      centeredBody
      modalBody={(
        <input type="date" value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value) }} />
          )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Confirmar"
      onClose={() => handleModalSpecificRegistrationDateShow(false)}
      onAction={() => {
        handleSpecificRegistrationDateChange(selectedDate);
        handleModalSpecificRegistrationDateShow(false);
      }}
      isActionButtonDisabled={!selectedDate}
      type="action"
      size="sm"
    />
  );
}
