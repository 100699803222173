import useDownloadExcel from './functions/useDownloadExcel';
import useLoadSubscribers from './functions/useLoadSubscribers';
import usePagination from './functions/usePagination';
import useSearches from './functions/useSearches';
import useSubscriberDetails from './functions/useSubscriberDetails';

export default function useActivities() {
  const {
    filteredSubscribers,
    setFilteredSubscribers,
    setSubscribersSplited,
    currentPage,
    setCurrentPage,
    pagesQuantity,
    handlePageChange,
  } = usePagination();

  const {
    isLoadLoading,
    fullSubscribers,
    doesListApiHasError,
    cuponsList,
    companysList,
    statusOptions,
    loadSubscribers,
    engagementOptions,
  } = useLoadSubscribers({
    setSubscribersSplited,
    setFilteredSubscribers,
    setCurrentPage,
  });

  const {
    searchTerm,
    selectedCupom,
    selectedCompany,
    selectedStatus,
    selectedKind,
    handleCompanyChange,
    handleCupomChange,
    handleStatusChange,
    handleKindChange,
    handleChangeSearchTerm,
    selectedEngagement,
    handleEngagementChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
    handleIsSpecificRegistrationDate,
    isSpecificRegistrationDate,
    handleModalSpecificRegistrationDateShow,
    registrationDateBeingShown,
    handleIsSpecificActivationDate,
    isSpecificActivationDate,
    handleModalSpecificActivationDateShow,
    activationDateBeingShown,
    modalSpecificRegistrationDateShow,
    modalSpecificActivationDateShow,
    handleSpecificRegistrationDateChange,
    handleSpecificActivationDateChange,
  } = useSearches({
    fullSubscribers,
    setSubscribersSplited,
    setFilteredSubscribers,
  });

  const {
    isSubscriberDetailsLoading,
    detailModalShow,
    detailView,
    subscriberDetailsBeingShow,
    setDetailView,
    handleOpenDetailModal,
    handleCloseDetailModal,
  } = useSubscriberDetails();

  const { downloadExcel } = useDownloadExcel();

  function handleTryAgain() {
    loadSubscribers();
  }

  const isLoading = isLoadLoading;

  return {
    isLoading,
    fullSubscribers,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredSubscribers,
    handleTryAgain,
    loadSubscribers,
    pagesQuantity,
    currentPage,
    companysList,
    selectedCompany,
    handleCompanyChange,
    cuponsList,
    selectedCupom,
    handleCupomChange,
    statusOptions,
    selectedStatus,
    handleStatusChange,
    selectedKind,
    handleKindChange,
    handlePageChange,
    handleOpenDetailModal,
    detailModalShow,
    subscriberDetailsBeingShow,
    detailView,
    setDetailView,
    handleCloseDetailModal,
    isSubscriberDetailsLoading,
    downloadExcel,
    selectedEngagement,
    engagementOptions,
    handleEngagementChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
    handleIsSpecificRegistrationDate,
    isSpecificRegistrationDate,
    handleModalSpecificRegistrationDateShow,
    registrationDateBeingShown,
    handleIsSpecificActivationDate,
    isSpecificActivationDate,
    handleModalSpecificActivationDateShow,
    activationDateBeingShown,
    modalSpecificRegistrationDateShow,
    modalSpecificActivationDateShow,
    handleSpecificRegistrationDateChange,
    handleSpecificActivationDateChange,
  };
}
