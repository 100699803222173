/* eslint-disable no-nested-ternary */
import 'react-range-slider-input/dist/style.css';
import Select from 'react-select';

import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import useThemeContext from '../../../../contexts/theme';
import { Container, RadioButtonsFilterGroup } from './styles';

import filter from '../../../../assets/images/icons/filter.svg';
import { CategoryType, PartnerType } from '../../types';

interface HandlePartnerTypeChangeInterface {
  value: string;
  label: string;
}

interface FiltersInterface {
  categoriesList: CategoryType[];
  selectedCategory: CategoryType;
  partnersList: PartnerType[];
  selectedPartner: PartnerType;
  selectedStatus: string;
  handleStatusChange: (status: string) => void;
  handlePartnerChange: ({ value, label }: HandlePartnerTypeChangeInterface) => void;
  handleCategoryChange: ({ value, label }: CategoryType) => void;
  handleTypeChange: (type: 'internal' | 'external' | 'all') => void;
  selectedType: 'all' | 'internal' | 'external';
}

export default function Filters({
  categoriesList,
  selectedCategory,
  partnersList,
  selectedPartner,
  selectedStatus,
  handleStatusChange,
  handlePartnerChange,
  handleCategoryChange,
  handleTypeChange,
  selectedType,
}: FiltersInterface) {
  const { selectedTheme } = useThemeContext();
  const partnersOptions = partnersList;
  const categoriesOptions = categoriesList;

  return (
    <Container>
      <header>
        <img src={filter} alt="filter" className="primaryColor" />
        <div>Pesquise através dos filtros abaixo</div>
      </header>

      <div style={{ justifyContent: 'center' }}>
        <Select
          value={{ value: selectedPartner?.value, label: selectedPartner?.label === 'Todos' ? 'Filtrar por parceiro' : selectedPartner?.label }}
          options={partnersOptions}
          onChange={(partner) => handlePartnerChange(partner as HandlePartnerTypeChangeInterface)}
          placeholder="Filtrar por parceiro"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        <Select
          value={{ value: selectedCategory?.value, label: selectedCategory?.label === 'Todas' ? 'Filtrar por categoria' : selectedCategory?.label }}
          options={categoriesOptions}
          onChange={(category) => handleCategoryChange(category as CategoryType)}
          placeholder="Filtrar por categoria"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />

      <RadioButtonsFilterGroup>
        <FilterRadioButtonsContainer>
          <FilterRadioButton
            onClick={() => handleStatusChange('')}
            selected={selectedStatus === ''}
          >
            Todos
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleStatusChange('Ativo')}
            selected={selectedStatus === 'Ativo'}
          >
            Ativos
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleStatusChange('Inativo')}
            selected={selectedStatus === 'Inativo'}
          >
            Inativos
          </FilterRadioButton>
        </FilterRadioButtonsContainer>

        <FilterRadioButtonsContainer>
          <FilterRadioButton
            onClick={() => handleTypeChange('all')}
            selected={selectedType === 'all'}
          >
            Todos
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleTypeChange('internal')}
            selected={selectedType === 'internal'}
          >
            Internos
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleTypeChange('external')}
            selected={selectedType === 'external'}
          >
            Externos
          </FilterRadioButton>
        </FilterRadioButtonsContainer>
      </RadioButtonsFilterGroup>
      </div>
    </Container>
  );
}
