import { Dispatch, SetStateAction } from 'react';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../../components/FilterRadioButtonsContainer';
import { BookTypeType } from '../../../types';

interface ITypeOfBook {
  setTypeOfBook: Dispatch<SetStateAction<BookTypeType>>;
  typeOfBook: BookTypeType;
  edit: boolean;
}

export default function TypeOfBook({
  setTypeOfBook,
  typeOfBook,
  edit,
}: ITypeOfBook) {
  return (
    <FilterRadioButtonsContainer>
      <FilterRadioButton
        onClick={() => setTypeOfBook('internal')}
        selected={typeOfBook === 'internal'}
        disabled={edit}
      >
        Interno
      </FilterRadioButton>
      <FilterRadioButton
        onClick={() => setTypeOfBook('external')}
        selected={typeOfBook === 'external'}
        disabled={edit}
      >
        Externo
      </FilterRadioButton>
    </FilterRadioButtonsContainer>
  );
}
