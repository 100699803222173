import { Col, Container, Row } from 'react-bootstrap';
import edit from '../../../../../assets/images/icons/edit.svg';
import trash from '../../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { CategoryBeingDeletedType, CategoryBeingEditedType, MappedCategoriesType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: MappedCategoriesType[];
  onOpenEditModal: ({ id, name }: CategoryBeingEditedType) => void;
  onOpenDeleteModal: ({ id, name }: CategoryBeingDeletedType) => void;
}

export default function List({
  filteredList,
  onOpenEditModal,
  onOpenDeleteModal
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={3}>
        {filteredList?.map((category) => (
          <Col key={category.id}>
            <OpacityAnimation delay={0.1}>
              <Card title="Expandir detalhes">
                <div className="info">
                  <div className="card-title">
                    <strong>{category.name}</strong>
                  </div>
                </div>
                <div className="actions">
                <button
                      type="button"
                      onClick={() => onOpenEditModal({
                        id: category.id,
                        name: category.name,
                      })}
                    >
                      <img src={edit} alt="" title={`Editar categoria ${category.name}`} className='primaryColor' />
                    </button>

                  {category.books.length === 0 && (
                    <button
                      type="button"
                      onClick={() => onOpenDeleteModal({
                        id: category.id,
                        name: category.name,
                      })}
                    >
                      <img src={trash} alt="" title={`Remover categoria ${category.name}`} />
                    </button>
                  )}
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
