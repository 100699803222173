import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { MappedCategoriesType } from "../../types";

interface IUseSearches {
  categories: MappedCategoriesType[];
  setFilteredList: Dispatch<SetStateAction<MappedCategoriesType[]>>
}

export default function useSearches({ categories, setFilteredList }: IUseSearches) {
  const [searchTerm, setSearchTerm] = useState('');

  const filterCategories = useCallback(() => {
    const filteredCategories = categories.filter((ct) => {
      const nameFilter = ct.name.toLowerCase().includes(String(searchTerm).toLowerCase());

      return nameFilter;
    });

    setFilteredList(filteredCategories);
  }, [categories, setFilteredList, searchTerm]);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  useEffect(() => {
    filterCategories();
  }, [filterCategories]);

  return {
    searchTerm,
    handleChangeSearchTerm,
  }
}
