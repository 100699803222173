import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import BookForm from "./components/BookForm";

export default function EditBook() {
  return (
    <>
      <Sidebar active={'Books'} />
      <Transitions>
        <BookForm edit />
      </Transitions>
    </>
  );
}
