/* eslint-disable no-nested-ternary */
import {
  Dispatch,
  SetStateAction,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import subscribersService from '../../../../services/subscribersService';
import dateWith0 from '../../../../utils/dateWith0';
import removeDuplicates from '../../../../utils/removeDuplicates';
import splitArray from '../../../../utils/splitArray';
import { MappedSubscriber, SubscriberFromApi } from '../../types';

interface UseLoadSubscribersInterface {
  setSubscribersSplited: Dispatch<SetStateAction<Array<MappedSubscriber[]>>>;
  setFilteredSubscribers: Dispatch<SetStateAction<MappedSubscriber[]>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function useLoadSubscribers({
  setSubscribersSplited,
  setFilteredSubscribers,
  setCurrentPage,
}: UseLoadSubscribersInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [fullSubscribers, setFullSubscribers] = useState<MappedSubscriber[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [cuponsList, setCuponsList] = useState([{ value: '', label: 'Todas' }]);
  const [companysList, setCompanysList] = useState([{ value: '', label: 'Todas' }]);

  const { apiCall } = useApiCall();

  const statusOptions = useMemo(() => (
    [
      { value: '', label: 'Todos' },
      { value: 'Ativo', label: 'Ativo' },
      { value: 'Cancelado', label: 'Cancelado' },
      { value: 'Teste', label: 'Período de teste' },
      { value: 'Suspenso', label: 'Suspenso' },
    ]
  ), []);

  const engagementOptions = useMemo(() => (
    [
      { value: '', label: 'Todos' },
      { value: 'great', label: 'Ótimo' },
      { value: 'warn', label: 'Mediano' },
      { value: 'bad', label: 'Ruim' },
    ]
  ), []);

  const loadSubscribers = useCallback(async () => {
    await apiCall({
      apiToCall: subscribersService.getSuscribersList,
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a lista de assinantes (${apiResponse.error})`, {
            toastId: 'getSubscribersFail',
          });
          setDoesListApiHasError(true);
          setIsLoadLoading(false);
          return;
        }
        const subscribersList: SubscriberFromApi[] = apiResponse.list;
        const statusLiterals: { [key: string]: string } = {
          Ativa: 'Ativo',
          'Período de teste': 'Teste',
          Cancelada: 'Cancelado',
          Suspensa: 'Suspenso',
        }
        const mappedSubscribersList = subscribersList?.map((subscriber) => ({
          id: subscriber.id,
          name: subscriber.nome,
          email: subscriber.email,
          status: statusLiterals[subscriber.assinatura_status],
          company: subscriber.company?.nome_fantasia || subscriber.company?.nome || 'B2C',
          activateDate: dateWith0(subscriber.data_ativacao),
          registrationDate: dateWith0(subscriber.data_cadastro.split(' ')[0]),
          kind: subscriber.company ? 'b2b' : 'b2c',
          cupom: subscriber?.cupom?.name || subscriber?.cupom_utilizado?.code || 'Sem cupom',
          collaboratorId: subscriber.collaborator?.id || 'Sem identificador de empresa',
          cellphone: subscriber.celular || 'Não informado',
          engagementStatus: subscriber.engagementStatus === 'horrible' ? 'bad' : subscriber.engagementStatus,
          kids: subscriber.kids,
        }));

        const companys = removeDuplicates(mappedSubscribersList.map((sub) => sub.company));
        const companysMapped = companys.map((company) => ({ value: company, label: company }));
        companysMapped.unshift({ value: '', label: 'Todas' });
        const companiesFiltered = companysMapped.filter((company) => company.label !== 'JaSouKiddle');
        setCompanysList(companiesFiltered);

        const cupons = removeDuplicates(mappedSubscribersList.map((cp) => cp.cupom));
        const cuponsMapped = cupons.map((cp) => ({ value: cp, label: cp }));
        cuponsMapped.unshift({ value: 'Sem cupom', label: 'Sem cupom' });
        cuponsMapped.unshift({ value: '', label: 'Todos' });
        setCuponsList(cuponsMapped);

        setFullSubscribers(mappedSubscribersList);

        const splitedArray = splitArray(mappedSubscribersList);
        setSubscribersSplited(splitedArray);
        setFilteredSubscribers(splitedArray[0]);
        setCurrentPage(0);
        setDoesListApiHasError(false);
      },
      catchAction: () => setDoesListApiHasError(true),
      catchMessage: 'Não foi possível carregar a lista dos assinantes'
    })
  }, [apiCall, setCurrentPage, setFilteredSubscribers, setSubscribersSplited]);

  useEffect(() => {
    loadSubscribers();
  }, [loadSubscribers]);

  return {
    isLoadLoading,
    fullSubscribers,
    doesListApiHasError,
    cuponsList,
    companysList,
    statusOptions,
    loadSubscribers,
    engagementOptions,
  };
}
